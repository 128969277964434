export default {

    methods: {
        async loadAncestorPermissions(groupId) {
            const offset = 0;
            const response = await this.$store.$coreApi.coreUserApi.getGroupAncestors(
                this.selectedDomain,
                groupId,
                this.limit,
                offset
            );
            const ancestors = response?.groups ?? [];
            let permissions = [];
            for (const ancestor of ancestors) {
                const id = ancestor.id;
                const permissionResponse = await this.getGroupPermissions(
                    id,
                    this.limit,
                    offset
                );
                const directPermissions = (permissionResponse?.permissions ?? []).map(
                    (permission) => {
                        return {
                            ...permission,
                            ancestor,
                        };
                    }
                );
                const ancestorPermissions = await this.loadAncestorPermissions(id);
                permissions = permissions
                    .concat(directPermissions)
                    .concat(ancestorPermissions);
            }
            return permissions;
        },

        async getGroupPermissions(groupId, limit = -1, offset = 0) {
            try {
                const response =
                    await this.$store.$coreApi.coreUserApi.getGroupPermissions(
                        this.selectedDomain,
                        groupId,
                        limit,
                        offset
                    );
                return response;
            } catch (e) {
                console.log(e);
                return null;
            }
        },

        getGroupRoute(domain, groupId) {
            return {
                name: "groupDetail",
                params: {
                    group: groupId,
                    domain,
                    keepParams: true,
                },
            };
        },

        getGroupLink(domain, groupId) {
            const route = this.getGroupRoute(domain, groupId);
            return this.$router.resolve(route).href;
        },

        async openGroupDetail(domain, group) {
            const route = this.getGroupRoute(domain, group);
            return await this.$router.push(route);
        },
    }

}