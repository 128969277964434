<template>
  <div class="common-data-table-container">
    <v-toolbar flat>
      <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
      <v-divider v-if="title" vertical inset class="mx-3" />
      <slot name="actions" :loading="loading" />
    </v-toolbar>
    <v-data-table
      :dense="dense"
      hide-default-footer
      class="data-table"
      :loading="loading"
      :items="items"
      :headers="headers"
      :items-per-page="limit"
    >
      <!-- eslint-disable-next-line -->
      <template #body="{ items }">
        <tbody>
          <tr v-if="items.length === 0" class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">{{ noDataText }}</td>
          </tr>
          <tr v-else v-for="(item, index) in items" :key="index + items.length">
            <td v-for="header in headers" :key="header.value">
              <slot
                :name="'item.' + header.value"
                :item="item"
                :header="header"
                :loading="loading"
              >
                {{ $getObjectValueByPath(item, header.value) }}
              </slot>
            </td>
          </tr>
          <slot name="body.append" :items="items" />
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    limit: {
      type: Number,
      required: false,
      default: -1,
    },

    title: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    noDataText: {
      type: String,
      required: false,
      default: "No data available",
    },

    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>
.common-data-table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}
</style>