<template>
  <DialogForm
    class="new-permission-form"
    data-test-id="permission"
    title="New Permission"
    save-btn-label="Grant"
    close-btn-label="Cancel"
    :loading="runningAction"
    @close="$emit('close', false)"
    @save="grantPermission"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="permissionForm">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              <v-select
                v-model="permission.type"
                dense
                outlined
                label="Type*"
                class="permission-type"
                data-test-id="permissionTypeSelect"
                hide-details="auto"
                :disabled="loading || disabled"
                :items="permissionTypes"
                :rules="[ruleSet.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DomainSelector
                v-model="resourceDomain"
                label="Resource domain*"
                outlined
                data-test-id="permissionResourceDomainSelector"
                hide-details="auto"
                disable-domain-creation
                set-local
                :rules="[ruleSet.required]"
                :disabled="loading || disabled"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "components/common/templates/DialogForm";
import DomainSelector from "components/domain/DomainSelector";
import validationMixin from "mixins/field-rule-validation";

export default {
  mixins: [validationMixin],
  components: {
    DialogForm,
    DomainSelector,
  },

  props: {
    entityId: {
      type: String,
      required: true,
    },

    isGroup: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      runningAction: false,
      permission: {},
      resourceDomain: null,
    };
  },

  watch: {
    resourceDomain(domain) {
      this.$set(this.permission, "resourceDomain", domain?.id);
    },
  },

  methods: {
    async grantPermission() {
      try {
        this.runningAction = true;
        const form = this.$refs.permissionForm;
        if (this.$validateVForm(form)) {
          const type = this.permission?.type;
          const resourceDomain = this.permission?.resourceDomain;
          const successMsg =
            "<strong>Granted</strong> permission <strong>" +
            type +
            "</strong> for domain <strong>" +
            resourceDomain +
            "</strong>";

          const userApi = this.$store.$coreApi.coreUserApi;
          let response;

          if (this.isGroup) {
            response = await userApi.grantGroupPermission(
              this.selectedDomain,
              this.entityId,
              type,
              resourceDomain,
              { successMsg }
            );
          } else {
            response = await userApi.grantUserPermission(
              this.selectedDomain,
              this.entityId,
              type,
              resourceDomain,
              { successMsg }
            );
          }

          if (response.ok) {
            this.$emit("close", true);
          }
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    permissionTypes() {
      return [{ text: "DOMAIN_ACCESS", value: "DOMAIN_ACCESS" }];
    },
  },
};
</script>

<style scoped>
</style>